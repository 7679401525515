import React from "react";
import PropTypes from "prop-types";
import { InstantSearch, SearchBox, Hits, Stats, Pagination } from "react-instantsearch/dom";
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import {baseStyles} from '../Button'

import Hit from "./Hit";

const SearchWrapper = styled.div`
  .ais-SearchBox {
    width: 100%;
  }
  .ais-SearchBox-form {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .ais-SearchBox-input {
    padding: 0 1em;
    font-size: 1.4em;
    flex-grow: 1;
  }
  .ais-SearchBox-submit {
    ${baseStyles}
    display: none;
    margin-left: -1px;
    svg {
      width: 2em;
      height: 2em;
      vertical-align: middle ;
    }
  }
  .ais-SearchBox-reset {
    background: none;
    border: none;
    display: none;
    fill: #666;
    flex-grow: 0;
  }
  .ais-Stats {
    margin: .5em 0 2em .3em;
    font-size: .9em;
    color: #999;
    display: block;
  }
  .ais-Hits-list {
    list-style: none;
    padding: 0;
  }
  .ais-Pagination-list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
  }
  .ais-Pagination-item {
    a, span {
      color: #666;
      font-size: 1.2em;
      display: block;
      padding: .5em .5em 2em;
      ${breakpoint('large')`
        font-size: 1.3em;
        padding: .5em .7em 0;
      `}
    }
    a {
      hover {
        color: theme.base.colors.accent;
      }
    }
    .ais-Pagination-item--firstPage, .ais-Pagination-item--previousPage, .ais-Pagination-item--nextPage {
      a, span {
        padding: .4em .5em .6em;
        ${breakpoint('large')`
          padding: .4em .7em .6em;
        `}
      }
    }
  }
  a {
    font-weight: 400;
  }
`

const Search = props => {
  const { algolia } = props;

  return (
    <SearchWrapper>
      {algolia &&
        algolia.appId && (
          <InstantSearch
            appId={algolia.appId}
            apiKey={algolia.searchOnlyApiKey}
            indexName={algolia.indexName}
          >
            <SearchBox
              translations={{ placeholder: "Search" }}
              autoFocus
            />
            <Stats />
            <Hits hitComponent={Hit} />
          </InstantSearch>
        )}
    </SearchWrapper>
  );
};

Search.propTypes = {
  algolia: PropTypes.object.isRequired
};

export default Search;
