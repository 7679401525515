import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import styled from 'styled-components'

const HitWrapper = styled.div`
  a {
    padding: .5em 0 1em 1em,
    position: relative,
    font-size: 1em,
    display: block,
    width: 100%,
    color: #666,
    &::before: {
      content: '•',
      position: absolute,
      top: .5em,
      left: .1em,
      color: theme.base.colors.accent
    },
    & span: {
      font-weight: 300,
      display: block,
      font-size: .7em,
      margin: .2em 0 0 0
    }
  }
`

const Hit = props => {
  const { hit } = props;

  return (
    <HitWrapper>
      <Link to={`/${hit.slug}/`}>
        {hit.title}
        {hit.subTitle && <span> - {hit.subTitle}</span>}
      </Link>
    </HitWrapper>
  );
};

Hit.propTypes = {
  hit: PropTypes.object.isRequired
};

export default Hit;
