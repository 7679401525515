import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Search from '../components/Search'
import Heading from '../components/Heading'
import { Algolia as AlgoliaIcon } from 'styled-icons/fa-brands/Algolia'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Article from '../components/Article'

const StyledAlgoliaIcon = styled(AlgoliaIcon)`
  width:1em;
  color:#5867F6;
`

const SearchPage = props => {
  const { data } = props;

  return (
    <Layout>
      <Article>
        <Heading.H1>Search by <StyledAlgoliaIcon /> algolia</Heading.H1>
        <Search algolia={data.site.siteMetadata.algolia} />
      </Article>
    </Layout>
  );
};

SearchPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default SearchPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query AlgoliaQuery {
    site {
      siteMetadata {
        algolia {
          appId
          searchOnlyApiKey
          indexName
        }
      }
    }
  }
`;
